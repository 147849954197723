import React, { useState, useEffect, useMemo } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import NavLink from "../../components/UI/navLink";
import Link from "gatsby-link";

const Header = ({ handleModal }) => {
  // What?
  // Maintaining styles on the header link "Projects" when the /projects is refreshed.
  // * activeClassNames is not active when /prjojects page is refreshed?.
  // Adding styles to the "Projects" header link when sub directories of /projects are active.
  // How?
  // Every time the component mounts check if the current pathname matches the /projects routes.
  // Update the isProjectsRouteActive(boolean) state accordingly when the routes change.
  // Apply active and idle classes when the routes change.

  const [isProjectsRouteActive, setIsProjectsRouteActive] = useState(null);
  useEffect(() => {
    if (
      window.location.pathname === "/projects/" ||
      window.location.pathname === "/projects/fernweh" ||
      window.location.pathname === "/projects/rosa" ||
      window.location.pathname === "/projects/fernweh/" ||
      window.location.pathname === "/projects/rosa/"
    ) {
      setIsProjectsRouteActive(true);
    } else {
      setIsProjectsRouteActive(false);
    }
  }, [isProjectsRouteActive, setIsProjectsRouteActive]);

  // useScrollPosition hook that reports the window scroll position.
  // Then we toggle "header" and "header-hidden" classes based on scroll position.
  // Also add box shadow on the header based on scroll position and when it's hidden.
  // Finally remove box shadow on the header when the user is at the top of the page.

  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [headerStyle, setHeaderStyle] = useState({});
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
      if (currPos.y < -50 && isShow === true)
        setHeaderStyle({ boxShadow: "0px 0px 1px #828282" });
      if (currPos.y >= -50) setHeaderStyle({ boxShadow: "none" });
    },
    [hideOnScroll, headerStyle],
    false,
    false,
    300
  );

  return useMemo(
    () => (
      <header
        id={hideOnScroll ? "header" : "header-hidden"}
        style={{ ...headerStyle }}
      >
        <div className="nav-items">
          <NavLink url={""} label={"About"} />
          <Link
            to="/projects/"
            className={
              isProjectsRouteActive ? "route-label-active label" : "label"
            }
          >
            Projects
          </Link>
          <button
            className="contact-btn label"
            onClick={() => handleModal("hidden", true)}
          >
            Contact
          </button>
        </div>
      </header>
    ),
    [hideOnScroll, isProjectsRouteActive, handleModal, headerStyle]
  );
};

export default Header;

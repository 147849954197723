import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import NavBtn from "../components/UI/navBtn";
import Layout from "../components/layouts/index";

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "Error/error_img.png" }) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div id="error-page">
        <div className="content-wrapper">
          <Img
            fluid={data.image.childImageSharp.fluid}
            alt="404 error image"
            className="error-img"
          ></Img>

          <div className="text-container">
            <h2 className="main-heading">404: Error</h2>
            <p className="main-text">
              Oops, unfortunately this site does not exsist.
            </p>
            <NavBtn url={""} label={"Go home"} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

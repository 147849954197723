import React from "react";
import { IoIosClose } from "@react-icons/all-files/io/IoIosClose";

const ContactModal = ({ handleModal, isModalVisible }) => {
  return (
    <div
      id={
        isModalVisible
          ? "contact-modal-wrapper"
          : "contact-modal-wrapper-closed"
      }
    >
      <div
        onClick={() => handleModal("visible", false)}
        onKeyDown={() => handleModal("visible", false)}
        role="button"
        aria-label="Modal backdrop"
        tabIndex="0"
        id={
          isModalVisible
            ? "contact-modal-backdrop"
            : "contact-modal-backdrop-closed"
        }
      ></div>
      <div id={isModalVisible ? "contact-modal-open" : "contact-modal-closed"}>
        <div className="content-wrapper">
          <div className="close-btn-container">
            <button className="close-btn">
              <IoIosClose
                role="button"
                tabIndex="0"
                size={40}
                onClick={() => handleModal("visible", false)}
              />
            </button>
          </div>
          <div className="contact-info-container">
            <div className="contact-info">
              <a
                className="label-bold"
                href="mailto:g.rosuson@gmail.com"
                rel="noreferrer"
                target="_blank"
              >
                g.rosuson@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;

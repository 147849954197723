import React from "react";

const Footer = () => {
  return (
    <footer id="footer-container" className="footer-theme-dark">
      <div className="contact-box">
        <span className="footer-heading">contact</span>
        <a
          href="mailto:g.rosuson@gmail.com"
          target="_blank"
          rel="noreferrer"
          className="footer-items"
        >
          g.rosuson@gmail.com
        </a>
        <a
          href="https://www.linkedin.com/in/gu%C3%B0mundur-helgi-63bb6a191/"
          target="_blank"
          rel="noreferrer"
          className="footer-items"
        >
          LinkedIn
        </a>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import Header from "../constants/Header";
import Footer from "../constants/Footer";
import ContactModal from "../modals/contactModal";

export default function Layout({ children }) {
  const [isModalVisible, setModalVisible] = useState(false);

  const handleModal = (overflow, showModal) => {
    setModalVisible(showModal);
    document.body.style.overflow = overflow;
  };

  return (
    <div id="app-container">
      <Header handleModal={handleModal} />
      <ContactModal handleModal={handleModal} isModalVisible={isModalVisible} />
      {children}
      <Footer props={children} />
    </div>
  );
}

import React from "react";
import Link from "gatsby-link";

const NavLink = ({ label, url }) => {
  return (
    //  "/" + navigationData = is a fix for an external link error from Gatsby

    <div className="route-label-container">
      <Link
        className="label"
        activeClassName="route-label-active"
        to={"/" + url}
      >
        {label}
      </Link>
    </div>
  );
};

export default NavLink;
